<template>
  <fade @parentEvent="close">
    <div class="fadeConfirmChild share-course-contain">
      <div class="title">选择分享类型</div>
      <div @click="jumpShareCourse(1)" class="item blue">
        <div class="left">
          <div class="t1">分享邀请卡</div>
          <div class="t2">自动生成海报，识码进入课程详情，快速报名</div>
        </div>
        <div class="right icon1"></div>
      </div>
      <div v-if="showTable[1]" @click="jumpShareCourse(2)" class="item green">
        <div class="left">
          <div class="t1">分享H5直播间</div>
          <div class="t2">扫码直达直播间，支持手机/电脑多端观看</div>
        </div>
        <div class="right icon2"></div>
      </div>
      <div v-if="showTable[2]" @click="jumpShareCourse(3)" class="item red">
        <div class="left">
          <div class="t1">分享小程序</div>
          <div class="t2">支持视频连麦/互动白板，超低延迟，适合教学培训</div>
        </div>
        <div class="right icon3"></div>
      </div>
      <div class="close" @click="close">取消</div>
    </div>
  </fade>
</template>
<script>
import fade from '@/components/FadeAnimation'

export default {
  name: 'ShareCourse',

  props: {
    shareCourseId: [String, Number]
  },

  components: {
    fade
  },

  data() {
    return {
      showTable: [true, true, true]
    }
  },

  created() {
    this.$root.$http({
      name: 'shareShareLimit',
      isMultipleSelf: 'replacePrev',
      url: `/Share/shareLimit?course_id=${this.shareCourseId}`,
      callback: ({ showTable }) => {
        this.showTable = showTable
      }
    })
  },

  methods: {
    jumpShareCourse(type) {
      this.$emit('setIframeSrc', `/Share/coursePosterShare?course_id=${this.shareCourseId}&type=${type}`)
      this.close()
    },

    close() {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.share-course-contain {
  padding: 0 0.7rem;
  box-sizing: border-box;
  background-color: #f5f5f5;
  .title {
    font-size: 0.65rem;
    font-weight: 500;
    line-height: 0.9rem;
    text-align: center;
    padding: 0.6rem 0 0.95rem;
    color: rgba(102, 102, 102, 1);
  }
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
    border-radius: 0.2rem;
    padding: 0.8rem 0.85rem;
    &.blue {
      background: #3d91fd;
    }
    &.green {
      background: #39be88;
    }
    &.red {
      background: #f15cb5;
    }
    .left {
      flex: 1;
      .t1 {
        color: #fff;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1.05rem;
      }
      .t2 {
        color: #fff;
        font-weight: 500;
        font-size: 0.55rem;
        line-height: 0.8rem;
        margin-top: 0.25rem;
      }
    }
    .right {
      width: 1.3rem;
      height: 1.3rem;
      margin: 0 0.3rem 0 1rem;
      &.icon1 {
        background: url('../assets/img/courseAdmin/ico_yak@2x.png') no-repeat center;
        background-size: contain;
      }
      &.icon2 {
        background: url('../assets/img/courseAdmin/ico_zbj@2x.png') no-repeat center;
        background-size: contain;
      }
      &.icon3 {
        background: url('../assets/img/courseAdmin/ico_xcx@2x.png') no-repeat center;
        background-size: contain;
      }
    }
  }
  .close {
    cursor: pointer;
    font-size: 0.65rem;
    font-weight: 500;
    line-height: 0.9rem;
    text-align: center;
    padding: 0.95rem 0 0.6rem;
    color: rgba(153, 153, 153, 1);
  }
}
</style>
