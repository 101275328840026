<template>
  <fade @parentEvent="close">
    <div v-show="!removeConfirm && !sellCourseConfirm" class="fadeConfirmChild SeniorSetting-confirm-content">
      <div class="title">高级设置</div>
      <div class="item">
        <div class="top">
          <span class="t1">观看有效期</span>
          <span class="t2"
            >自学生购买后
            <i class="recordedValidity">{{ recordedValidity }}</i>
            个月内有效</span
          >
        </div>
        <p class="prompt">如不设置，默认学生购买后12个月内有效</p>
        <div class="bottom">
          <label @touchmove.stop class="inputRange">
            <input
              :step="1"
              min="1"
              max="12"
              type="range"
              v-model="recordedValidity"
              @change="save(1, recordedValidity, 'recordedValidity')"
              :style="{
                background: '-webkit-linear-gradient(0deg, #27dcd3, #27dcd3) 0% 0% / ' + ((recordedValidity - 1) * 100) / 11 + '% 100% no-repeat'
              }"
            />
          </label>
          <div class="range-tab">
            <span v-for="item in 12" :key="`recordedValidity${item}`">{{ item }}</span>
          </div>
        </div>
      </div>
      <div class="item" v-if="course_type != 4">
        <div class="top">
          <span class="t1">禁止报名</span>
          <label class="switch-label">
            <input @change="save(2, sign_up, 'sign_up')" v-model="sign_up" :true-value="2" :false-value="1" class="switch-input" type="checkbox" />
            <span class="switch-box"></span>
          </label>
        </div>
        <p class="prompt gray">禁止报名后，未报名的学生无法再报名此课程</p>
        <ul v-if="sign_up == 2 && issue_info.length > 1">
          <li v-for="(item, index) in issue_info.slice(0, isShowAllIssue ? issue_info.length : 2)" :key="`issue${index}`" class="setting-issue">
            <label class="setting-issue-label">
              <span>{{ item.issue }}</span>
              <input type="checkbox" name="recordPlay[]" :value="item.issue_id" v-model="selectIssue" />
              <i></i>
            </label>
          </li>
          <li v-if="issue_info.length > 2" @click="showAllIssue" class="show-all-issue">
            <span class="text">{{ isShowAllIssue ? '收起全部期数' : '展示全部期数' }}</span>
            <span class="icon" :class="{ reverse: !isShowAllIssue }"></span>
          </li>
        </ul>
      </div>
      <div class="item" v-if="course_type != 4">
        <div class="top">
          <span class="t1">允许学生页面出现小程序观看指引</span>
          <label class="switch-label">
            <input @change="save(3, is_direct, 'is_direct')" v-model="is_direct" class="switch-input" type="checkbox" :true-value="1" :false-value="2" />
            <span class="switch-box"></span>
          </label>
        </div>
        <p class="prompt gray">
          学生可以通过邀请卡进入到购买页面，扫小程序码观看直播，延迟更低，建议开启
        </p>
      </div>
      <div class="item rmAndXj">
        <span class="vm fn1" @click="removeConfirm = true"><i class="vm icon"></i><i class="vm text">删除</i></span>
        <span class="vm fn2" @click="sellCourse"
          ><i class="vm icon"></i><i class="vm text">{{ status == 1 ? '下架' : '上架' }}</i></span
        >
      </div>
    </div>
    <!--弹窗提示-->
    <confirm
      :class="{ transparent: removeConfirm || sellCourseConfirm }"
      v-if="sellCourseConfirm"
      title="下架课程"
      :btnRed="true"
      @cancel="close"
      @complete="toggleSell"
      content="下架后课程不在网校显示，学生不可购买课程；已购买的学生不影响看课"
    ></confirm>
    <!--删除-->
    <confirm
      :class="{ transparent: removeConfirm || sellCourseConfirm }"
      v-if="removeConfirm"
      :btnRed="true"
      :center="true"
      @cancel="close"
      @complete="remove"
      content="你确定要永久删除？<br>删除后无法复原"
    ></confirm>
  </fade>
</template>
<script>
import fade from '@/components/FadeAnimation'
import confirm from '@/components/Confirm'

export default {
  name: 'SeniorSetting',

  components: {
    fade,
    confirm
  },

  props: {
    course_type: [String, Number],
    course_id: [String, Number],
    isTeam: Boolean,
    index: {
      type: [String, Number],
      default: ''
    },
    status: {
      type: [String, Number],
      default: ''
    }
  },

  data() {
    return {
      is_direct: 1,
      issue_info: [],
      selectIssue: [],
      isShowAllIssue: false,
      recordedValidity: 12,
      sign_up: 2,
      sellCourseConfirm: false,

      removeConfirm: false
    }
  },

  created() {
    this.getData()
  },

  watch: {
    // 监听选择期数变化
    selectIssue() {
      if (!this.selectIssue.isNoUpdate) {
        const changeIssue = []
        const copyData = []
        this.issue_info.forEach(item => {
          const temp = Object.assign({}, item)
          const status = Number(item.sign_up) !== 2
          const index = this.selectIssue.indexOf(item.issue_id) > -1
          const exp = index && status
          // 如果选中的期数中存在 并且是关闭状态
          // 或者 不存在并且开关是开启状态
          if (exp || (!index && !status)) {
            temp.sign_up = exp ? 2 : 1
            changeIssue.push(temp)
          }
          copyData.push(Object.assign({}, temp))
        })

        this.sign_up = this.selectIssue.length > 0 ? 2 : 1

        if (changeIssue.length > 0) {
          this.save(
            2,
            {
              copyData,
              type: 4,
              value: changeIssue
            },
            'sign_up'
          )
        } else {
          this.clearTimeout(2)
        }
      }
    }
  },

  methods: {
    getData() {
      this.$http({
        name: 'course_seniorSetting',
        url: `/course/seniorSetting?course_id=${this.course_id}`,
        isMultipleSelf: 'replacePrev',
        errorMessage: '获取高级设置信息失败，请检查网络或重新点击加载',
        callback: ({ data: { is_direct, recorded_viedo_validity, issue_info } }) => {
          this.setIssueData(issue_info)
          this.is_direct = Number(is_direct)
          this.recordedValidity = Number(recorded_viedo_validity) || 12
        }
      })
    },

    // 课程上下架 处理弹窗
    sellCourse() {
      if (this.isTeam && Number(this.status) === 1) {
        this.$root.prompt('该课程正在拼课，无法下架')
      } else if (Number(this.status) === 1) {
        this.sellCourseConfirm = true
      } else {
        this.toggleSell()
      }
    },

    // 课程上下架处理方法
    toggleSell() {
      this.$http({
        isLoading: true,
        name: 'toggleSell',
        url: '/Course/isShelves',
        data: {
          status: this.status,
          course_id: this.course_id
        },
        callback: ({ info }) => {
          const status = Number(this.status) === 1 ? 2 : 1
          this.updateParentValue('isTeam', false)
          this.updateParentValue('status', status)
          if (info) {
            this.$root.prompt({
              msg: info,
              type: 'success'
            })
          }
          this.$emit('toggleSellCallback')
          this.close()
        }
      })
    },

    // 删除课程
    remove() {
      this.$emit('removeCourseConfirmStart')
      this.close()
    },

    updateParentValue(name, value) {
      this.$emit(`update:${name}`, value)
    },

    // 显示全部期数
    showAllIssue() {
      this.isShowAllIssue = !this.isShowAllIssue
    },

    close() {
      this.$emit('close')
    },

    // 设置期数数据
    setIssueData(issue_info) {
      const arr = []
      issue_info.forEach(item => {
        if (Number(item.sign_up) === 2) {
          arr.push(item.issue_id)
        }
      })
      arr.isNoUpdate = true
      this.selectIssue = arr
      this.issue_info = issue_info
      this.sign_up = this.selectIssue.length > 0 ? 2 : 1
    },

    clearTimeout(type) {
      if (this[`setting${type}`]) {
        clearTimeout(this[`setting${type}`])
      }
    },

    save(type, value) {
      this.clearTimeout(type)
      const courseId = this.course_id
      this[`setting${type}`] = setTimeout(() => {
        this.$http({
          name: `saveSeniorSetting${type}`,
          url: '/course/seniorSetting',
          isMultipleSelf: 'replacePrev',
          data: {
            value: value.value || value,
            type: value.type || type,
            course_id: courseId
          },
          errorMessage: '设置失败，请检查网络或重试',
          error: () => {
            if (value.type) {
              this.setIssueData(this.issue_info)
            }
          }
        })
      }, 700)
    }
  }
}
</script>
<style lang="scss">
.transparent {
  background: transparent;
}
.SeniorSetting-confirm-content {
  width: 414px;
  max-height: 500px;
  background-color: #fff;
  > .title {
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 0.9rem;
    text-align: center;
    word-break: break-word;
    padding: 0.7rem 1rem 1.5rem;
    color: rgba(153, 153, 153, 1);
  }
  > .item {
    padding: 0 0.7rem;
    position: relative;
    margin-bottom: 0.75rem;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      transform: scaleY(0.5);
      border-bottom: 1px solid #f5f5f5;
    }
    .top {
      display: flex;
      align-items: center;
      .t1 {
        flex: 1;
        overflow: hidden;
        font-size: 0.7rem;
        font-weight: bold;
        line-height: 1rem;
        white-space: nowrap;
        padding-right: 2rem;
        text-overflow: ellipsis;
        color: rgba(52, 52, 52, 1);
      }
      .t2 {
        font-size: 0.6rem;
        font-weight: bold;
        line-height: 0.85rem;
        color: rgba(52, 52, 52, 1);
      }
    }
    &:last-child {
      margin-bottom: 0;
      &:after {
        display: none;
      }
    }
  }
  .prompt {
    font-size: 0.6rem;
    font-weight: 400;
    margin-top: 0.55rem;
    line-height: 0.85rem;
    color: rgba(255, 53, 53, 1);
  }
  .gray {
    color: #999;
    padding-bottom: 0.9rem;
  }
}
/**录播进度条***/
.recordedValidity {
  color: #1da9a2;
  font-size: 0.7rem;
}
.inputRange {
  width: 100%;
  display: block;
  position: relative;
}
input[type='range'] {
  width: 100%;
  height: 4px;
  outline: none;
  display: block;
  margin: 1rem 0 0;
}
input[type='range' i] {
  color: transparent;
  padding: 0;
  height: 4px;
  border-radius: 3px;
  background-color: #1da9a2;
}
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: #fff;
  position: relative;
  right: -1px;
  top: -9px;
  z-index: 5;
  box-sizing: border-box;
  border: 6px solid #1da9a2;
  border-radius: 50%; /*外观设置为圆形*/
}
input[type='range']::-webkit-slider-runnable-track {
  /*轨道*/
  height: 4px;
  background: rgba(0, 0, 0, 0.2);
}
.range-tab {
  width: 100%;
  font-size: 0;
  padding: 1rem 0 0.5rem;
}
.range-tab > span {
  width: 8.33%;
  color: #999;
  text-align: center;
  font-size: 0.65rem;
  vertical-align: top;
  display: inline-block;
}
.rangeValue {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: #343434;
  font-size: 0.65rem;
  font-weight: bold;
}
.rangeValue i {
  color: #1da9a2;
  min-width: 1.2rem;
  text-align: center;
  display: inline-block;
}
/***input 动画***/
.switch-label {
  position: relative;
  top: 0.2rem;
}
.switch-input {
  display: none;
}
.switch-box {
  display: block;
  position: relative;
  width: 50px;
  height: 26px;
  outline: 0;
  border-radius: 13px;
  background-color: #cacaca;
  -webkit-transition: background-color 0.1s, border 0.1s;
  transition: background-color 0.1s, border 0.1s;
}
.switch-box:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 26px;
  border-radius: 12px;
  background-color: #bababa;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.45, 1, 0.4, 1);
  transition: transform 0.35s cubic-bezier(0.45, 1, 0.4, 1);
}
.switch-box:after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35);
  transition: transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35);
}
.switch-input:checked + .switch-box {
  border-color: #1db3aa;
  background-color: #1db3aa;
}
.switch-input:checked + .switch-box:before {
  -webkit-transform: scale(0);
  transform: scale(0);
}
.switch-input:checked + .switch-box:after {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}
/***选择期数**/
.setting-issue {
  padding-bottom: 0.75rem;
  > .setting-issue-label {
    display: flex;
    align-items: center;
    span {
      flex: 1;
      font-size: 0.6rem;
      font-weight: bold;
      line-height: 0.85rem;
      color: rgba(51, 51, 51, 1);
    }
    input {
      display: none;
      & + i {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        position: relative;
        background-color: #c7c7c7;
        &:after {
          content: '';
          position: absolute;
          border-color: #fff;
          border-style: solid;
          left: 0.25rem;
          top: 0.15rem;
          width: 0.15rem;
          height: 0.3rem;
          transform: rotate(35deg);
          border-width: 0 0.1rem 0.1rem 0;
        }
      }
      &:checked + i {
        background-color: #1db3aa;
      }
    }
  }
}
.show-all-issue {
  font-size: 0;
  text-align: center;
  padding-bottom: 0.9rem;
  > * {
    display: inline-block;
    vertical-align: middle;
  }
  .text {
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 0.85rem;
    color: rgba(29, 169, 162, 1);
  }
  .icon {
    width: 0.5rem;
    height: 0.3rem;
    margin-left: 0.25rem;
    background-size: 100%;
    background-image: url('../../assets/img/courseAdmin/more.png');
  }
  .reverse {
    transform: rotate(180deg);
  }
}
/**删除 下架**/
.rmAndXj {
  font-size: 0;
  position: relative;
  &:before {
    content: '';
    left: 50%;
    top: 0.5rem;
    width: 1px;
    height: 0.9rem;
    position: absolute;
    background: rgba(213, 213, 213, 1);
  }
  %v1 {
    width: 50%;
    text-align: center;
    padding: 0.5rem 0 0.95rem;
    .icon {
      margin-right: 0.45rem;
    }
    .text {
      font-size: 0.7rem;
      font-weight: 500;
      line-height: 1rem;
    }
  }
  > .fn1 {
    @extend %v1;
    .icon {
      width: 0.55rem;
      height: 0.7rem;
      background: url('../../assets/img/courseAdmin/ico_sc_gj@2x.png') no-repeat center 0 / contain;
    }
    .text {
      color: #ff3535;
    }
  }
  > .fn2 {
    @extend %v1;
    .icon {
      width: 0.7rem;
      height: 0.7rem;
      background: url('../../assets/img/courseAdmin/ico_xj_gj@2x.png') no-repeat center 0 / contain;
    }
    .text {
      color: #1ca7a0;
    }
  }
}
</style>
