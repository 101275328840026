<template>
  <fade @parentEvent="close">
    <div class="fadeConfirmChild estimated-content">
      <h3 class="basePayCount-title">购买基数</h3>
      <p class="estimated-detailPrompt">
        学生端报名人数显示 = 实际报名人数 + 基数
      </p>
      <div class="estimated-input">
        <input v-focus v-model.trim="myBaseSales" placeholder="输入购买基数" maxlength="6" />
      </div>
      <div class="confim-btn">
        <span @click="close">取消</span>
        <span @click="save" class="confirm-yellow">确定</span>
      </div>
    </div>
  </fade>
</template>
<script>
import fade from '@/components/FadeAnimation'

export default {
  name: 'BasePayCount',

  props: {
    basesales: [String, Number],
    course_id: [String, Number]
  },

  components: {
    fade
  },

  data() {
    return {
      myBaseSales: this.basesales
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },

    save() {
      const regExp = /^\d+$/.test(this.myBaseSales)
      if (!this.myBaseSales) {
        this.$root.prompt('请输入购买基数')
      } else if (regExp && this.myBaseSales > 0) {
        this.$root.$http({
          name: 'addBaseSales',
          url: '/Course/setBasesales',
          data: {
            basesales: this.myBaseSales,
            course_id: this.course_id
          },
          callback: () => {
            this.$emit('update:basesales', this.myBaseSales)
            this.close()
          }
        })
      } else {
        this.$root.prompt(regExp ? '请输入整数' : '请输入数字')
      }
    }
  }
}
</script>
<style>
/**报名人数**/
.basePayCount-title {
  color: #333;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.15;
  padding: 0.75rem 0 0.5rem;
}
.estimated-detailPrompt {
  color: #666;
  font-size: 0.6rem;
  text-align: center;
  padding: 0 1rem;
}
.estimated-content {
  width: 414px;
  border-radius: 2px;
  background-color: #fff;
}
.estimated-input {
  margin: 0.25rem 0 1rem;
  padding: 0 1rem;
  box-sizing: border-box;
}
.estimated-input > input {
  width: 100%;
  height: 2.5rem;
  border-radius: 2px;
  display: block;
  font-size: 0.7rem;
  padding: 0 0.5rem;
  background-color: #f9f9f9;
  box-sizing: border-box;
  margin: 0.5rem auto;
  border: 1px solid #e8e8e8;
}
/***报名人数End***/
/**弹出框选择按钮**/
.confim-btn {
  font-size: 0;
}
.confim-btn > * {
  border-top: 1px solid #f5f5f5;
  font-size: 0.75rem;
  width: 50%;
  padding: 0.75rem 0;
  color: #666;
  display: inline-block;
  text-align: center;
}
.confirm-yellow {
  color: #f39700;
}
</style>
