<template>
  <fade-animation @parentEvent="maskCkCancel">
    <div @touchmove.prevent class="fadeConfirmChild confirmComponent">
      <div class="texts">
        <h2 class="title" v-if="title">{{ title }}</h2>
        <slot name="slot0"></slot>
        <div class="content" :class="{ textCenter: center }" :style="contentStyle" v-html="content"></div>
        <slot name="slot1"></slot>
      </div>
      <div class="button">
        <button @click="cancel" :style="btn1Style" v-if="isShowCancelBtn">
          {{ cancelText || '取消' }}
        </button>
        <i class="spaces" v-if="isShowCancelBtn"></i>
        <button @click="complete" :style="btn2Style" :class="{ myButtonStyle: loadBtnStyle, red: btnRed }">
          {{ completeText || '确定' }}
        </button>
      </div>
    </div>
  </fade-animation>
</template>
<script>
import fadeAnimation from '@/components/FadeAnimation'

export default {
  name: 'confirm',

  components: {
    fadeAnimation
  },

  props: {
    title: String,
    content: String,
    cancelText: String,
    completeText: String,
    loadBtnStyle: {
      type: Boolean,
      default: false
    },
    isShowCancelBtn: {
      type: Boolean,
      default: true
    },
    center: {
      type: Boolean,
      default: false
    },
    maskClick: {
      type: Boolean,
      default: true
    },
    contentStyle: String,
    btn1Style: String,
    btn2Style: String,
    btnRed: Boolean
  },

  methods: {
    maskCkCancel() {
      if (this.maskClick) {
        this.$emit('cancel', 1)
      }
    },

    cancel() {
      this.$emit('cancel')
    },

    complete() {
      this.$emit('complete')
    }
  }
}
</script>
<style lang="scss">
.confirmComponent {
  width: 300px;
  display: flex;
  flex-flow: column;
  border-radius: 0.2rem;
  word-break: break-word;
  background-color: #fff;
  .texts {
    flex: 1;
    overflow: hidden;
    padding: 1.4rem 0.7rem 0.9rem;
    .title {
      color: #333;
      font-weight: 400;
      font-size: 0.8rem;
      text-align: center;
      margin-top: -0.85rem;
      line-height: 1.1rem;
      margin-bottom: 0.95rem;
    }
    .content {
      width: 100%;
      color: #666666;
      font-weight: 400;
      font-size: 0.6rem;
      line-height: 1.1rem;
      word-break: break-all;
      &.textCenter {
        text-align: center;
      }
    }
  }
  .button {
    font-size: 0;
    display: flex;
    align-items: center;
    position: relative;
    button {
      flex: 1;
      width: 50%;
      border: 0;
      color: #999;
      font-size: 0.7rem;
      padding: 0.6rem 0;
      text-align: center;
      background-color: transparent;
      &.red {
        color: #ff3535 !important;
      }
    }
    button:last-child {
      color: #1da9a2;
    }
    button.myButtonStyle {
      color: #fff;
      margin: 1rem;
      font-size: 0.75rem;
      border-radius: 0.2rem;
      background-color: #f59800;
    }
    .spaces {
      height: 2rem;
      transform: scale(0.5);
      border-right: 1px solid #ededed;
    }
  }
}
</style>
