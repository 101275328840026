<template>
  <!---课程筛选-->
  <transition name="rightToLeft">
    <div class="course-category-contain">
      <div class="category-list">
        <div class="item">
          <div class="title">类型</div>
          <div class="btns">
            <div v-for="item in type" class="vm" :class="{ typeSelected: selected === item.id }" @click="select(item.id)" :key="item.id">
              <button>{{ item.text }}</button>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="title">老师</div>
          <div class="btns">
            <div
              class="new-bottom"
              :class="{ newSelected: teacher_id === teacherInfo[index].uid }"
              v-for="(item, index) in teacherInfo"
              :key="index"
              @click="getChooseTeacher(item.uid)"
            >
              <div class="teacher-photo" :style="`background-image: url(${item.uphoto})`"></div>
              <h3 class="teacher-name">{{ item.uname | strFliter }}</h3>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="title">分类</div>
          <div class="btns">
            <div v-for="item in category" :class="{ typeSelected: set_id === item.set_id }" @click="clickCategory(item.set_id)" :key="item.set_id">
              <button>{{ item.set_name }}</button>
            </div>
          </div>
          <div v-if="category.length === 0" class="empty-category">
            {{ categoryErMsg || '点击“设置分类”按钮创建分类' }}
          </div>
        </div>
      </div>
      <div class="button">
        <button class="b1" @click="reset">重置</button>
        <button class="b2" @click="clickComplete">确定</button>
      </div>
    </div>
  </transition>
</template>
<script>
import pageOverflow from '@/mixin/pageOverflow'

export default {
  name: 'CourseFilter',

  mixins: [pageOverflow],

  props: {
    currPsw: String,
    currSelected: {
      type: Number,
      default: 1
    },
    setId: String,
    teacherId: String
  },

  data() {
    const type = [
      { text: '已上架', id: 2 },
      { text: '已结束', id: 4 },
      { text: '禁止报名', id: 5 },
      { text: '不生成回放', id: 6 }
    ]
    // 如果在这些分类中 就选中这个分类
    let selected = type.filter(item => item.id === this.currSelected)[0]
    // 否则选中全部
    selected = selected ? selected.id : 1
    return {
      category: [],
      categoryErMsg: '',
      psw: '',
      set_id: this.setId,
      selected,
      type,
      // 老师信息
      teacherInfo: [],
      teacher_id: this.teacherId
    }
  },

  created() {
    this.getScreeningData()
  },

  filters: {
    strFliter(str) {
      return str.length > 5 ? `${str.slice(0, 5)}...` : str
    }
  },

  methods: {
    // 点击类型
    select(id) {
      this.selected = this.selected === id ? '' : id
    },

    // 选择分类
    clickCategory(set_id) {
      this.set_id = this.set_id === set_id ? '' : set_id
    },

    // 选择特定老师
    getChooseTeacher(uid) {
      if (this.teacher_id === uid) {
        this.teacher_id = ''
        return
      }
      this.teacher_id = uid
    },

    reset() {
      this.psw = ''
      this.set_id = ''
      this.teacher_id = ''
      this.select(1)
    },

    clickComplete() {
      const setIdEmpty = this.setId || this.set_id
      const setTeacherIdEmpty = this.teacherId || this.teacher_id
      // 默认选中全部
      if (!this.selected) {
        this.select(1)
      }
      if (
        !this.psw &&
        this.currSelected === this.selected &&
        (!setIdEmpty || this.setId === this.set_id) &&
        (!setTeacherIdEmpty || this.teacherId === this.teacher_id)
      ) {
        this.close()
      } else {
        // 筛选不带上搜索条件
        this.psw = ''
        this.commit()
      }
    },

    // 向上传递结果
    commit() {
      const options = {
        set_id: this.set_id || '',
        searchContent: this.psw || '',
        course_type: this.selected,
        teacher_id: this.teacher_id || '',
        isCourseFilter: true
      }
      if (options.course_type === 1 && !options.set_id && !options.teacher_id) {
        this.$emit('reset', 1)
      } else {
        this.$emit('search', options)
      }
      this.close()
    },

    close() {
      this.$emit('close')
    },

    // 获取机构所有老师
    getJigouTeacher() {
      this.$root.$http({
        isMultipleSelf: 'replacePrev',
        name: 'getJigouTeacher',
        url: '/Course/getJigouTeacher',
        callback: ({ data: { teacherInfo } }) => {
          this.teacherInfo = teacherInfo
        }
      })
    },

    // 获取用户自定义的类别
    getScreeningData() {
      this.$root.$http({
        isMultipleSelf: 'replacePrev',
        name: 'course_courseSet',
        url: '/Course/courseSet',
        callback: ({ data }) => {
          this.categoryErMsg = ''
          this.category = data || []
        },
        errorCallback: () => {
          this.categoryErMsg = '获取分类数据失败，请关闭重试或检查网络'
        }
      })
      this.getJigouTeacher()
    }
  }
}
</script>
<style lang="scss" scoped>
/**课程分类**/
.course-category-contain {
  position: fixed;
  top: 0;
  z-index: 90;
  height: 100%;
  width: auto;
  right: 0;
  background-color: #fff;
  > .category-list {
    width: 375px;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 0 0.5rem 2.9rem;
    > .item {
      .title {
        font-size: 0.6rem;
        font-weight: bold;
        line-height: 0.85rem;
        padding: 0.7rem 0 0.4rem;
        color: rgba(102, 102, 102, 1);
      }
      > .btns {
        font-size: 0;
        > div {
          width: 33.33%;
          text-align: center;
          position: relative;
          vertical-align: top;
          margin-bottom: 0.45rem;
          display: inline-block;
          button {
            position: relative;
            width: 4.4rem;
            height: 1.5rem;
            font-weight: 400;
            font-size: 0.55rem;
            color: #333333;
            background-color: #f5f5f5;
            border: 0;
          }
          .teacher-photo {
            width: 1.5rem;
            height: 1.5rem;
            margin: 0 auto 0.2rem;
            border: 2px solid #fff;
            border-radius: 50%;
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .teacher-name {
            font-size: 0.55rem;
            font-weight: 400;
            color: rgba(153, 153, 153, 1);
            line-height: 0.8rem;
          }
        }
        .new-bottom {
          margin-bottom: 1rem;
        }
        .newSelected {
          .teacher-photo {
            border: 2px solid rgba(10, 162, 155, 1);
          }
          .teacher-name {
            color: rgba(10, 162, 155, 1);
          }
        }
      }
    }
  }
  > .button {
    height: 2.4rem;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 0;
    & > button {
      width: 50%;
      border: 0;
      cursor: pointer;
      font-size: 0.8rem;
      display: inline-block;
      vertical-align: top;
    }
    .b1 {
      height: 100%;
      color: #333333;
      background-color: #fff;
      border-top: 1px solid #e6e6e6;
    }
    .b2 {
      color: #fff;
      height: 100%;
      background-color: #1da9a2;
      border-top: 1px solid #1da9a2;
    }
  }
}
.rightToLeft-enter-active {
  transition: all 0.25s ease 80ms;
}
.rightToLeft-leave-active {
  transition: all 0.2s ease;
}
.rightToLeft-enter, .rightToLeft-leave-to /* .fade-leave-active in <2.1.8 */ {
  transform: translateX(100%);
}
/**类型选中**/
.typeSelected {
  &:before {
    content: '';
    width: 0.8rem;
    height: 0.8rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    background: url('../../assets/img/courseAdmin/horns.png') no-repeat center 0 / contain;
  }
  button {
    color: #1da9a2 !important;
    background-color: #e8f6f5 !important;
  }
}
.empty-category {
  font-size: 0.6rem;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 0.85rem;
}
.screening-icon {
  margin: 0 1rem;
  font-size: 0 !important;
  & i:first-child {
    font-size: 0.6rem;
  }
  .icon {
    width: 0.45rem;
    height: 0.5rem;
    margin: 1px 0 0 0.15rem;
    background: url('../../assets/img/courseAdmin/icon_sx_kcgl@2x.png') no-repeat center 0 / contain;
  }
}
.settingCategory-icon {
  font-size: 0 !important;
  & i:first-child {
    font-size: 0.6rem;
  }
  .icon {
    width: 0.65rem;
    height: 0.65rem;
    margin: 1px 0 0 0.15rem;
    background: url('../../assets/img/courseAdmin/icon_szfl_kcgl@2x.png') no-repeat center 0 / contain;
  }
}
</style>
