<template>
  <div
    @click.prevent.stop
    :style="custom"
    class="function-btn"
    :class="{
      'is-free': isFree && buttonData.length == 6,
      'is-free2': isFree && buttonData.length == 7
    }"
  >
    <button
      v-for="(item, index) in buttonData"
      :key="`button${index}`"
      :style="item.style"
      :class="{ delete: item.delete }"
      @click.stop.prevent="$emit(item.emit || `fn${index + 1}`)"
    >
      {{ item.value }}
    </button>
  </div>
</template>
<script>
export default {
  name: 'FunctionButton',

  props: {
    buttons: Array,
    custom: {
      type: String,
      default: ''
    },
    isFree: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default() {
        return []
      }
    }
  },

  computed: {
    buttonData() {
      return this.buttons.map((item, index) =>
        Object.assign(
          {},
          {
            value: item
          },
          this.options[index] || {}
        )
      )
    }
  }
}
</script>
<style lang="scss">
/**编辑 删除**/
.function-btn {
  position: absolute;
  top: 80px;
  right: 20px;
  width: 5.4rem;
  z-index: 79 !important;
  animation: jelly 0.25s;
  background-color: #fff;
  border: 1px solid #f9f2f2;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24);
}
.function-btn button {
  position: relative;
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  border: none;
  outline: none;
  padding-left: 0.95rem;
  color: rgba(51, 51, 51, 1);
  font-size: 0.6rem;
  text-align: left;
  background-color: transparent;
  box-sizing: border-box;
  z-index: 91;
}
.is-free button:nth-child(3),
.is-free button:nth-child(4) {
  color: #c8c8c8;
}
.is-free2 button:nth-child(3),
.is-free2 button:nth-child(4),
.is-free2 button:last-child {
  color: #c8c8c8;
}
@keyframes jelly {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
