<template>
  <transition name="fade">
    <div @touchmove.prevent class="mask" @click="close"></div>
  </transition>
</template>
<script>
export default {
  name: 'my-mask',

  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
<style></style>
