<template>
  <div class="courseAdmin-contain">
    <div class="courseAdmin-menu">
      <button class="create-course" @click="createCourse">创建课程</button>
      <div class="course-category">
        <span
          v-for="item in category"
          :key="item.name"
          class="vm item"
          @click="selected(item.id)"
          :class="{
            selected:
              !postData.isCourseFilter && postData.course_type === item.id,
          }"
        >
          {{ item.name }}
        </span>
        <span
          v-if="postData.isCourseFilter"
          class="vm filter"
          @click="selected(1)"
        >
          <i class="vm text">清空筛选</i>
          <i class="vm icon"></i>
        </span>
      </div>
      <div class="right-btn">
        <div
          v-if="isJiGouAdmin"
          class="btn1"
          @click="setIframeSrc('/Course/setCourseSet')"
        >
          <span class="vm icon"></span>
          <span class="vm text">分类管理</span>
        </div>
        <div class="btn2" @click="toggleCourseFilter">
          <span class="vm icon"></span>
          <span class="vm text">筛选</span>
        </div>
      </div>
    </div>
    <div class="course-list-contain">
      <div class="course-list-table">
        <span class="item item1">课程名称</span>
        <div class="item2">
          <span class="vm item item2-1">类型</span>
          <span class="vm item item2-2">上课老师</span>
          <span class="vm item item2-3">章节/课时</span>
          <span class="vm item item2-4">已报名</span>
        </div>
        <span class="item item6">操作</span>
      </div>
      <ul @click="resetCurrSelectedClass" class="course-list">
        <li
          v-for="(item, index) in classList"
          :key="item.course_id"
          class="item"
          :class="{ hover: currCourseItem.id === index }"
        >
          <div class="item1 info">
            <div class="cover">
              <lazy class="img" :src="item.photo"></lazy>
              <div v-if="item.status == 2" class="shangjia">已下架</div>
            </div>
            <div class="right">
              <div class="title">{{ item.name }}</div>
              <div class="price">
                <span class="vm p1" v-if="item.fee_pwd">
                  {{ `报名密码：${item.fee_pwd}` }}
                </span>
                <span class="vm p1" v-else-if="item.course_type == 4">
                  {{ `￥${item.miniPrice}`
                  }}{{
                    item.miniPrice == item.maxPrice ? '' : ` - ${item.maxPrice}`
                  }}
                </span>
                <span class="vm p1" v-else>
                  {{ item.issue_price > 0 ? `￥${item.issue_price}` : '免费' }}
                </span>
                <span v-if="item.is_coupon" class="vm tag tag0"></span>
                <span v-if="item.isTeam" class="vm tag tag1"></span>
                <span v-if="item.is_fission" class="vm tag tag2"></span>
                <span v-if="item.is_redeem" class="vm tag tag3"></span>
              </div>
            </div>
          </div>
          <div class="item2">
            <div class="vm type item2-1">
              {{ item.course_type | courseType }}
            </div>
            <div class="vm teacher item2-2">
              {{ getTeacherNames(item.teacherInfo) }}
            </div>
            <div class="vm count item2-3">{{ item.chapter_num || 0 }}</div>
            <div class="vm sigUp item2-4">{{ item.totalsales || 0 }}</div>
          </div>
          <div class="function item6">
            <span
              class="vm btn"
              @click="
                setIframeSrc(
                  (item.course_type == 4
                    ? '/Course/reservation'
                    : '/Course/issueAdmin') + `?course_id=${item.course_id}`
                )
              "
              title="章节管理"
            >
              章节管理
            </span>
            <span class="vm space"></span>
            <span
              class="vm btn"
              @click.stop="toggleSellCourseConfirm(index, item.course_id)"
              :title="item.status == 2 ? '上架' : '下架'"
            >
              {{ item.status == 2 ? '上架' : '下架' }}
            </span>
            <span class="vm space"></span>
            <span
              class="vm btn"
              @click.stop="toggleCourseShare(item.course_id)"
              title="分享"
            >
              分享
            </span>
            <span class="vm space"></span>
            <span class="vm btn" @click.stop="openSetting(index)" title="更多">
              更多
            </span>
          </div>
          <!--更多设置-->
          <function-button
            :buttons="[
              '编辑课程信息',
              '虚拟报名人数',
              '评论管理',
              '高级设置',
              item.customer_status == 1 ? '已开启客服' : '已关闭客服',
            ]"
            :isFree="isVersionFree"
            v-if="settingId === index"
            @fn1="
              setIframeSrc(
                `/Course/courseEdit?course_id=${item.course_id}&course_type=${item.course_type}`
              )
            "
            @fn2="
              isVersionFree
                ? $root.toggleUpdatePopup()
                : toggleSigUpNumberConfirm(index, item.course_id)
            "
            @fn3="
              isVersionFree
                ? $root.toggleUpdatePopup()
                : setIframeSrc(
                    `/Review/courseReviewAdmin?course_id=${item.course_id}&uid=${item.uid}`
                  )
            "
            @fn4="toggleSettingConfirm(index, item.course_id)"
            @fn5="
              isVersionFree
                ? $root.toggleUpdatePopup()
                : openService(index, item.course_id, item.customer_status)
            "
            :options="
              isVersionFree
                ? [
                    '',
                    { style: 'color:#00000045' },
                    { style: 'color:#00000045' },
                    '',
                    { style: 'color:#00000045' },
                  ]
                : []
            "
          ></function-button>
        </li>
      </ul>
      <!--加载更多-->
      <loadMore
        ref="loadMore"
        :option="postData"
        :count="classList.length"
        @complete="loadMoreClass"
      ></loadMore>
    </div>
    <!--内嵌网页-->
    <my-iframe
      v-if="iframeSrc"
      :src="iframeSrc"
      @close="iframeSrc = ''"
    ></my-iframe>
    <!--创建课程弹窗-->
    <createClass
      v-if="createCourseConfirm"
      @close="createCourse"
      @setIframeSrc="setIframeSrc"
      :live_class="userInfo.live_class"
      :dingdou="userInfo.t_amount"
      :private_classes="userInfo.private_classes"
    ></createClass>
    <!--分享-->
    <share
      v-if="shareCourseId"
      :shareCourseId="shareCourseId"
      @close="toggleCourseShare"
      @setIframeSrc="setIframeSrc"
    ></share>
    <!-----高级设置----->
    <senior-setting
      :index="currCourseItem.id"
      v-bind.sync="currCourseItem.value"
      v-if="showSettingConfirm"
      @close="toggleSettingConfirm"
      @toggleSellCallback="toggleSellCallback"
      @removeCourseConfirmStart="removeCourseConfirmStart"
    ></senior-setting>
    <!--报名人数-->
    <setSigUpNumber
      v-if="showSigUpNumber"
      v-bind.sync="currCourseItem.value"
      @close="toggleSigUpNumberConfirm"
    ></setSigUpNumber>
    <!--弹窗提示-->
    <confirm
      v-if="sellCourseConfirm"
      title="下架课程"
      :btnRed="true"
      @cancel="toggleSellCourseConfirm"
      @complete="toggleSell"
      content="下架后课程不在网校显示，学生不可购买课程；已购买的学生不影响看课"
    ></confirm>
    <!--筛选-->
    <my-mask v-if="showCourseFilter" @close="toggleCourseFilter"></my-mask>
    <courseFilter
      v-if="showCourseFilter"
      :setId="postData.set_id"
      :currSelected="postData.course_type"
      :teacherId="postData.teacher_id"
      @search="searchCourseFilter"
      @close="toggleCourseFilter"
      @reset="selected"
    ></courseFilter>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import share from '@/components/ShareCourse'
import confirm from '@/components/Confirm'
import courseFilter from './CourseFilter'
import functionButton from './FunctionButton'
import seniorSetting from './SeniorSetting'
import setSigUpNumber from './BasePayCount'
import myMask from '@/components/Mask'
import loadMore from '@/components/LoadMoreData'
import createClass from '@/components/CreateCourseConfirm'

export default {
  name: 'CourseAdmin',

  components: {
    myMask,
    share,
    confirm,
    loadMore,
    createClass,
    courseFilter,
    seniorSetting,
    setSigUpNumber,
    functionButton,
  },

  data() {
    return {
      category: [
        { name: '全部', id: 1 },
        { name: '大班', id: 9 },
        { name: '小班', id: 7 },
        { name: '已下架', id: 3 },
      ],

      iframeSrc: '',
      createCourseConfirm: false,

      // 加载数据 选中的类型
      postData: {
        page: 1,
        course_type: 1,
        url: '/Course/courseAdmin',
      },

      // 分享
      shareCourseId: '',

      classList: [],

      // 课程设置
      settingId: '',

      // 高级设置
      currCourseItem: {
        id: '',
        value: {},
      },
      showSettingConfirm: false,

      // 报名人数
      showSigUpNumber: false,

      // 上下架
      sellCourseConfirm: false,

      // 课程筛选
      showCourseFilter: false,
    }
  },

  computed: {
    ...mapState(['userInfo']),
    ...mapGetters(['isVersionFree', 'isJiGouAdmin']),
  },

  filters: {
    courseType(course_type) {
      let name
      switch (Number(course_type)) {
        case 2:
          name = '录播'
          break
        case 4:
          name = '小班'
          break
        default:
          name = '直播'
          break
      }
      return name
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.classList = []
    this.postData = {
      page: 1,
      course_type: 1,
      url: '/Course/courseAdmin',
    }
    this.$refs.loadMore.reset()
    next()
  },
  methods: {
    createCourse() {
      this.createCourseConfirm = !this.createCourseConfirm
    },

    setIframeSrc(src) {
      this.iframeSrc = src
    },

    // 课程筛选
    toggleCourseFilter() {
      this.showCourseFilter = !this.showCourseFilter
    },

    searchCourseFilter(option) {
      this.classList = []
      this.postData = Object.assign({}, this.postData, option)
      this.$nextTick(this.$refs.loadMore.reset)
    },

    // 分享课程
    toggleCourseShare(course_id = '') {
      this.shareCourseId = course_id
    },

    // 更多设置
    openSetting(index) {
      this.settingId = index
    },

    resetCurrSelectedClass() {
      this.settingId = ''
      this.currCourseItem = {
        id: '',
        value: {},
      }
    },

    // 上下架
    toggleSellCourseConfirm(index = '', course_id) {
      this.publicConfirmFn(
        index,
        course_id,
        'sellCourseConfirm',
        typeof index === 'number' && Number(this.classList[index].status) === 2
          ? this.toggleSell
          : null
      )
    },

    // 报名人数
    toggleSigUpNumberConfirm(index = '', course_id) {
      this.publicConfirmFn(index, course_id, 'showSigUpNumber')
    },

    // 高级设置
    toggleSettingConfirm(index = '', course_id) {
      this.publicConfirmFn(index, course_id, 'showSettingConfirm')
    },

    // 公用函数
    publicConfirmFn(index = '', course_id, name, callback) {
      if (typeof index === 'number') {
        this.currCourseItem = {
          id: index,
          course_id,
          value: this.classList[index],
        }
      }
      if (typeof callback === 'function') {
        callback()
      } else {
        this.openSetting()
        this[name] = !this[name]
        if (!this[name]) {
          this.resetCurrSelectedClass()
        }
      }
    },

    // 课程上下架的回调
    toggleSell() {
      const index = this.currCourseItem.id
      const item = this.classList[index]
      this.$http({
        isLoading: true,
        name: 'toggleSell',
        url: '/Course/isShelves',
        data: {
          status: item.status,
          course_id: item.course_id,
        },
        callback: ({ info }) => {
          this.classList.splice(
            index,
            1,
            Object.assign({}, item, {
              status: Number(item.status) === 1 ? 2 : 1,
              isTeam: false,
            })
          )
          if (info) {
            this.$root.prompt(info)
          }
          this.toggleSellCallback(index)
          this.sellCourseConfirm = false
        },
      })
    },

    toggleSellCallback(index) {
      if (this.postData.course_type === 3) {
        this.classList.splice(index, 1)
      }
    },

    // 开启关闭客服
    openService(index, course_id, customer_status) {
      const status = Number(customer_status) === 1 ? 2 : 1
      this.$http({
        name: 'courseCustomerStatus',
        url: '/Course/courseCustomerStatus',
        data: {
          course_id: course_id,
          customer_status: status,
        },
        callback: () => {
          this.classList.splice(
            index,
            1,
            Object.assign({}, this.classList[index], {
              customer_status: status,
            })
          )
          this.$root.prompt({
            msg: status === 1 ? '已开启客服' : '已关闭客服',
            type: 'success',
          })
        },
      })
    },

    // 删除弹窗
    removeCourseConfirmStart() {
      const selectedRemoveCourse = this.classList[this.currCourseItem.id]
      if (selectedRemoveCourse.isTeam) {
        this.$root.prompt('该课程正在拼课，无法删除')
        return
      }
      this.removeCourseCbk()
    },

    // 删除课程
    removeCourseCbk() {
      this.$root.$http({
        name: 'removeCourseCbk',
        url: '/Course/deleteCourse',
        data: {
          courseInfo: [this.currCourseItem.course_id],
        },
        callback: () => {
          this.classList.splice(this.currCourseItem.id, 1)
          this.resetCurrSelectedClass()
        },
      })
    },

    // 老师名称
    getTeacherNames(value) {
      return value && value.length > 0
        ? value.map((item) => item.uname).join(',')
        : ''
    },

    // 选中类型 or 清空筛选
    selected(id) {
      this.classList = []
      this.postData = {
        page: 1,
        course_type: id,
        url: '/Course/courseAdmin',
      }
      this.$nextTick(this.$refs.loadMore.reset)
    },

    // 加载更多数据
    loadMoreClass(data) {
      this.classList = this.classList.concat(data.info)
    },
  },
}
</script>
<style lang="scss" scoped>
.courseAdmin-contain {
  height: 100%;
  background: #f5f5f5;
}
.courseAdmin-menu {
  display: flex;
  align-items: center;
  padding-top: 10px;
  .create-course {
    border: 0;
    width: 116px;
    height: 38px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-radius: 2px;
    margin: 0 29px 0 20px;
    color: rgba(255, 255, 255, 1);
    background: rgba(12, 170, 161, 1);
    &:hover {
      background: #0c9088;
    }
  }
  .course-category {
    flex: 1;
    font-size: 0;
    .item {
      cursor: pointer;
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      padding: 20px 21px;
      color: rgba(51, 51, 51, 1);
      &.selected {
        color: #0aa29b;
      }
    }
    .filter {
      font-size: 0;
      cursor: pointer;
      padding: 20px 21px;
      .icon {
        width: 10px;
        height: 10px;
        margin-left: 4px;
        background: url('../../assets/img/courseAdmin/ico_gb.png') no-repeat;
      }
      .text {
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        color: rgba(27, 157, 151, 1);
      }
    }
  }
  .right-btn {
    display: flex;
    align-content: center;
    %btn {
      font-size: 0;
      cursor: pointer;
      padding: 10px 15px;
      .icon {
        width: 14px;
        height: 12px;
      }
      .text {
        color: #333;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        margin-left: 6px;
        position: relative;
        top: 1px;
      }
    }
    .btn1 {
      @extend %btn;
      .icon {
        background: url('../../assets/img/courseAdmin/ico_flgl.png') no-repeat;
      }
    }
    .btn2 {
      @extend %btn;
      margin-right: -5px;
      .icon {
        background: url('../../assets/img/courseAdmin/ico_sx_sx.png') no-repeat;
      }
    }
  }
}
/**课程table**/
.course-list-contain {
  background: #fff;
  min-height: 100%;
  margin: 10px 0 0 20px;
}
%table {
  .item1 {
    flex: 1;
    min-width: 303px;
  }
  .item2 {
    font-size: 0;
    min-width: 410px;
    .item2-1,
    .item2-2,
    .item2-3,
    .item2-4 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .item2-1 {
      width: 84px;
    }
    .item2-2 {
      width: 131px;
      padding-right: 18px;
      box-sizing: border-box;
    }
    .item2-3 {
      width: 114px;
    }
    .item2-4 {
      width: 81px;
    }
  }
  .item6 {
    flex: 1;
    min-width: 176px;
    text-align: right;
  }
}
.course-list-table {
  display: flex;
  padding: 0 14px;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  @extend %table;
  .item {
    padding: 13px 0;
    font-size: 12px;
    line-height: 16px;
    color: rgba(51, 51, 51, 1);
  }
}
/**课程列表**/
.course-list {
  .item {
    font-size: 0;
    display: flex;
    position: relative;
    align-items: center;
    padding: 30px 0 20px 14px;
    border-bottom: 1px solid #eeeeee;
    &:hover,
    &.hover {
      background: #f5f5f5;
    }
    &:first-child {
      margin-top: -10px;
    }
    @extend %table;
    .info {
      display: flex;
      align-items: center;
      .cover {
        width: 130px;
        height: 72px;
        position: relative;
        .img {
          width: 100%;
          height: 100%;
        }
        .shangjia {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 62px;
          height: 62px;
          font-size: 12px;
          font-weight: bold;
          line-height: 62px;
          color: white;
          border-radius: 50%;
          background: black;
          text-align: center;
          transform: translate(-50%, -50%);
          background: rgba(0, 0, 0, 0.8);
        }
      }
      .right {
        flex: 1;
        max-width: 410px;
        margin-left: 8px;
        .title {
          font-size: 13px;
          font-weight: 500;
          line-height: 17px;
          min-height: 34px;
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: rgba(51, 51, 51, 1);
        }
        .price {
          margin-top: 25px;
          white-space: nowrap;
          .p1 {
            font-size: 12px;
            font-weight: 600;
            line-height: 17px;
            margin-right: 15px;
            color: rgba(255, 53, 53, 1);
          }
          .tag {
            width: 16px;
            height: 13px;
            margin-right: 5px;
          }
          .tag0 {
            background: url('../../assets/img/courseAdmin/ico_yhq.png')
              no-repeat;
          }
          .tag1 {
            background: url('../../assets/img/courseAdmin/ico_pk.png') no-repeat;
          }
          .tag2 {
            background: url('../../assets/img/courseAdmin/ico_liebian.png')
              no-repeat;
          }
          .tag3 {
            background: url('../../assets/img/courseAdmin/ico_dhm.png')
              no-repeat;
          }
        }
      }
    }
    .type,
    .teacher,
    .count,
    .sigUp {
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      color: rgba(51, 51, 51, 1);
    }
    .function {
      margin-right: 20px;
      .btn {
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        color: rgba(10, 162, 155, 1);
      }
      .space {
        width: 1px;
        height: 12px;
        margin: 0 8px;
        border-right: 1px solid rgba(10, 162, 155, 1);
      }
    }
  }
}
/**加载更多**/
.load-more {
  font-size: 0;
  text-align: center;
  padding-bottom: 96px;
  .btn {
    border: 0;
    cursor: pointer;
    font-size: 13px;
    padding: 18px 0;
    background: none;
    text-align: center;
    display: inline-block;
    color: rgba(27, 157, 151, 1);
  }
}
</style>
