<template>
  <div class="load-more" :class="{ none: !(loading || loading === -1 || count >= this.limit) }">
    <empty v-if="!loading && count === 0" style="padding: 100px 0;background-color: #fff;"></empty>
    <button @click="loadMoreClass" class="btn">
      {{ loading === -1 ? '加载失败，请点击重试...' : loading ? '数据加载中...' : loading !== 0 && count >= this.limit ? '加载更多' : '' }}
    </button>
  </div>
</template>
<script>
import empty from '@/components/Empty'

export default {
  name: 'LoadMoreData',

  components: {
    empty
  },

  props: {
    count: {
      type: Number,
      default: 0
    },
    option: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      loading: false,
      postData: Object.assign(
        {},
        {
          page: 1
        },
        this.option
      )
    }
  },

  computed: {
    limit() {
      return this.$store.state.userInfo.limit || 15
    }
  },

  created() {
    this.loadMoreClass()
  },

  methods: {
    // 加载更多数据
    loadMoreClass() {
      if (this.loading === 0) return
      this.loading = true
      this.$nextTick(() => {
        this.$http({
          name: this.option.url,
          url: this.option.url,
          data: this.postData,
          callback: ({ data }) => {
            this.$emit('complete', data)
            this.postData.page += 1
            this.loading = false
          },
          error: e => {
            if (e && e.code === 204) {
              this.loading = 0
            } else {
              this.loading = -1
            }
          }
        })
      })
    },

    // 重置数据
    reset() {
      this.loading = true
      this.postData = Object.assign(
        {},
        {
          page: 1
        },
        this.option
      )
      this.loadMoreClass()
    }
  }
}
</script>
<style scoped lang="scss">
/**加载更多**/
.load-more {
  font-size: 0;
  text-align: center;
  padding-bottom: 96px;
  &.none {
    padding-bottom: 0;
    .btn {
      padding: 0;
    }
  }
  .btn {
    border: 0;
    cursor: pointer;
    font-size: 13px;
    padding: 18px 0;
    background: none;
    text-align: center;
    display: inline-block;
    color: rgba(27, 157, 151, 1);
  }
}
</style>
